import Vue from 'vue'
import store from '../../../store'
import i18n from '@/modules/i18n'

import MyAccountDropdown from './my-account-dropdown.vue'

const vue = new Vue({
  el: '#my-account',
  store,
  i18n,
  components: {
    MyAccountDropdown
  }
})

window.vue = vue
