<template lang='pug'>
  div
    a.my-account(@click.prevent.stop='toggleMyAccountDropdown')
      span.my-account-icon
      span.my-account-text(v-text='$t("account.dashboard.title")')
    .my-account-dropdown(v-if='showMyAccountDropdown')
      .h5(v-text='$t("account.dashboard.title")')
      ul
        li.my-account-li(v-for='accountLink in accountLinks')
          a.my-account-link(:href='accountLink.path' @click='trackBeautyProfileClick(accountLink.path)' v-text='accountLink.label')
          p.new(v-if='accountLink.new' v-t='"common.new"')
      hr.hr

      .h5(v-text='$t("account.sephoraBeautyPass.title")')
      ul
        li.my-account-li
          a.my-account-link(
            :href='"/beauty-pass"'
            v-text='$t("account.beautyPassBenefits.title")'
          )
        li.my-account-li
          a.my-account-link(
            :href='"/rewards-boutique"'
            v-text='$t("account.rewardsBoutique.title")'
          )
        li.my-account-li
          a.my-account-link(
            :href='"/account/point_logs"'
            v-text='$t("account.pointLogs.pointsSummary")'
          )
      template(v-if='signedIn')
        hr.hr
        ul
          li.my-account-li
            SignOutLink
</template>

<script>
import { mapState } from 'vuex'
import { pushDataLayerEvent } from '@/modules/services/gtm'

const SignOutLink = () => import(
  /* webpackChunkName: 'sign-out-link' */ '@/components/shared/sign-out-link'
)

export default {
  components: { SignOutLink },

  props: [
    'signedIn',
    'userEmail',
    'userFirstName'
  ],

  data () {
    return {
      showMyAccountDropdown: false
    }
  },

  computed: {
    ...mapState({
      fullSizeEnabled: () => vueSupport?.featureFlags?.enableFullSize,
      beautyProfileEnabled: () => vueSupport?.featureFlags?.enableBeautyProfile
    }),

    accountLinks () {
      return [
        { label: this.$t('account.dashboard.dashboard'), path: '/account/dashboard' },
        { label: this.$t('account.orders.title'), path: '/account/orders' },
        {
          label: this.fullSizeEnabled ? this.$t('account.getItAgain.title') : this.$t('account.purchaseAgain.title'),
          path: this.fullSizeEnabled ? '/account/get-it-again' : '/account/purchased-items'
        },
        { label: this.$t('account.reviews.title'), path: '/account/reviews' },
        { label: this.$t('account.wishlist.title'), path: '/wishlist' },
        { label: this.$t('account.waitlist.title'), path: '/waitlists' },
        ...this.beautyProfileEnabled ? [this.beautyProfileLink()] : []
      ]
    }
  },

  created () {
    window.addEventListener('click', () => {
      this.showMyAccountDropdown = false
    })
  },

  destroyed () {
    window.removeEventListener('click', () => {
      this.showMyAccountDropdown = false
    })
  },

  methods: {
    beautyProfileLink () {
      return { label: this.$t('account.beautyProfile.title'), path: '/account/beauty-profile', new: true }
    },
    trackBeautyProfileClick (path) {
      if (path === '/account/beauty-profile') pushDataLayerEvent('beautyprofile_click_entry')
    },
    toggleMyAccountDropdown () {
      this.showMyAccountDropdown = !this.showMyAccountDropdown

      // remove search focus
      this.$store.commit('setFocusSearch', false)
      this.$store.commit('setSearchQuery', '')

      Modal.prototype._resetScrollbar() // eslint-disable-line no-undef
    }
  }
}
</script>

<style lang='scss' scoped>
  .my-account {
    user-select: none;
    min-width: 100px;

    &:hover {
      .my-account-icon {
        background-image: url('../../../assets/images/icons/account2-hover.svg');
      }

      .my-account-text {
        color: $brand-primary;
      }
    }
  }

  .my-account-icon {
    margin-right: 8px;
    margin-bottom: 5px;
    width: 13px;
    height: 15px;
    vertical-align: bottom;
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/icons/account2.svg');
    background-size: cover;
  }

  .my-account-text {
    text-transform: uppercase;
    color: $white;
  }

  .my-account-dropdown {
    user-select: none;
    cursor: default;
    position: absolute;
    top: 35px;
    padding: 10px;
    z-index: $zindex-fixed;
    color: $black !important;
    background: $white;
    border-top: none;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.13);

    .h5 {
      @include font-weight-bold;
      text-transform: uppercase;
      line-height: 28px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .hr {
      margin-top: 10px;
      margin-bottom: 15px;
      border: 1px solid $grey;
      border-bottom: none;
    }

    .my-account-li {
      display: flex;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .my-account-link {
      @include font-weight-book;
      margin-left: 15px;
      margin-right: 15px;
      font-size: 13px;
      text-transform: capitalize;
    }

    .new {
      padding: 0px 4px;
      background-color: $brand-primary;
      color: $white;
      font-size: 10px;
      text-transform: capitalize;
      border-radius: 4px;
    }
  }
</style>
